import * as React from "react";
import { useState } from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import cohorte from "@images/proyectos/cohorte.png";
import smartphone from "@images/proyectos/smartphone.png";
import adorno from "@images/adorno/adorno5.png";
import flujoperu from "@images/flujoperu.png";
import flujouruguay from "@images/flujouruguay.png";
import flujoargentina from "@images/flujoargentina.png";

const IndexPage = () => {

  const [select, setselect] = useState(-1)

  return (
    <TemplatePage1
      title={"Cohortes electrónicas"}
      hero={true}
      bg={"var(--tema4)"}
      tc={"white"}
      adorno={<Adorno />}
      content={(
        <Wrapper >
          <CardContainer>
            <Card>
              <Hexagon bgc={"var(--tema4)"}>
                <img src={cohorte} alt={cohorte} />
              </Hexagon>
              <TextZone>
                <h1 className={ "text-2xl font-bold" } onClick={() => {  }} >Cohortes electrónicas</h1>

                <div className={ "text-2xl text-justify show transition-all"}>
                  <p className={"text-xl"}>La e-Cohorte es una herramienta longitudinal para monitorear la calidad de la atención de salud para condiciones o enfermedades que requieren atención clínica y seguimiento continuo. Desde la Red QuEST LAC estamos evaluando la factibilidad del uso de e-Cohortes en poblaciones con HTA/DB como una herramienta flexible para evaluar el desempeño del sistema de salud en tres paises de la region (Perú, Uruguay y Argentina). 
</p>
                </div>

              </TextZone>
            </Card>
            <Card>
            <img className={""} src={flujoperu} alt={flujoperu} />
            </Card>
            <Card>
            <img className={""} src={flujouruguay} alt={flujouruguay} />
            </Card>
            <Card>
            <img className={""} src={flujoargentina} alt={flujoargentina} />
            </Card>
            <Card>
              <TextZone>
                <h1 className={ "text-2xl font-bold" } onClick={() => {  }} >TRABAJO DE CAMPO EN LOS PAISES:</h1>
                <h1 className={ "text-2xl font-bold" } onClick={() => {  }} >PERÚ:</h1>


                <div className={ "text text-justify show transition-all"}>
                  <ul>
                  <li>
                    Lugar: Pampas de San Juan de Miraflores (San Juan de Miraflores, Lima-Perú)
                    </li>
                    <li>
                    CRONICAS cohorte: 2010: 1500 personas
                    2020: 1059 personas 
                    </li>
                    <li>
                    Entrenamientos : 10 al 14 de octubre de 2022 
                    </li>
                    <li>
                    4 entrevistadores y 1 supervisor de campo 
                    </li>
                    <li>
                    Piloto: 15/10/2022 con 12 personas
                    </li>
                    <li>
                    Inicio del trabajo de campo: 17/10/2022 
                    </li>
                    <li>
                    Fin de trabajo de campo: 31/02/2024 
                    </li>
                    <li>
                    Estado: Informe final y resultados en revision de revistas cientificas.
                    </li>
                  </ul>

                </div>

                <h1 className={ "text-2xl font-bold" } onClick={() => {  }} >URUGUAY:</h1>


                <div className={ "text text-justify show transition-all"}>
                  <ul>
                  <li>Lugar: Centros de Atención Primaria de Salud (CAPS) en el departamento de Canelones, Montevideo.</li>
<li>CESCAS cohorte: 1501 (2017) personas</li>
<li>941 (2023) personas contactadas.</li>
<li>Entrenamientos: 14 al 28 septiembre de 2023</li>
<li>Piloto: Octubre 2023</li>
<li>Inicio del trabajo de campo: 1/11/2023</li>
<li>Estado: en marcha</li>
                    
                  </ul>

                </div>

                <h1 className={ "text-2xl font-bold" } onClick={() => {  }} >ARGENTINA:</h1>


<div className={ "text text-justify show transition-all"}>
  <ul>
  <li>Lugar: Centros de Atención Primaria de Salud (CAPS) de la provincia de Mendoza</li>
  <li>Se utilizó una base de datos del MSAL MDZ donde se extrajo pacientes DBT2 del año 2019. De ese total de pacientes, se lograron el contacto con 889.</li>
  <li>Entrenamientos: 1 al 15 diciembre de 2022</li>
  <li>Piloto: Enero 2023</li>
  <li>Inicio de llamados telefónicos: Marzo 2023</li>

    
  </ul>

</div>

              </TextZone>
            </Card>
          </CardContainer>
        </Wrapper >
      )}
    />
  )
};

export default IndexPage



const Hexagon = styled.div`
position: relative;
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
background-color: ${props => props.bgc ? props.bgc : ""};
background-size: cover;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;

@media only screen and (min-width:768px){
  height: 150px;
max-height:150px;
min-height:150px;
width: 150px;
max-width:150px;
min-width:150px;

}
height: 90px;
max-height:90px;
min-height:90px;
width: 90px;
max-width:90px;
min-width:90px;
img{
  height:70%;
}
`

const Wrapper = styled.div`
padding-top: 1rem;
`;

const CardContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`

const Card = styled.div`
border: solid 1px var(--tema4);
background-color:white;
border-radius: 20px;
display: flex;
flex-direction: ${props => props.left ? "row-reverse" : "row"};
justify-content: flex-start;
align-items: center;
padding: 2rem 1rem 2rem 1rem;
margin: 1rem 0 1rem 0;
width: 100%;
`;

const TextZone = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding:15px;
color: var(--tema4);
transition:all 1s;
will-change: contents;


h1{
  margin: 0.2rem 0 0.5rem 0;
  text-align: center;
  cursor: pointer;
}

.ownsmall h1{
  font-size:1rem;
}
.ownbig h1{
  font-size:2rem;
}


div{
  .ownshow{
    display: block;
  }
  .ownhidden{
    display: none;
  }
}
`;

const Adorno = styled.div`
position: absolute;
bottom: 0px;
right: 1100px;
height:100%;
@media only screen and (min-width:768px){
  bottom: 0px;
  left: -450px;
height:100%;
  width: 800px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  bottom: 0px;
left: -450px;
height:100%;
  width: 800px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
`;